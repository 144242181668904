html * {
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  font-size: 18px;
}

.App {
  min-height: 100vh;
  position: relative;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/header.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  border: solid 5px white;
  border-radius: 100%;
  overflow: hidden;
}

.logo img {
  max-height: 200px;
}

.Intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Intro p {
  width: 60%;
}

.error {
  padding: 10px;
  margin-left: 10px;
  background-color: #FF5511;
}

.Convert-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Form-input {
  width: 150px;
}

.Convert-form .Form-input {
  font-size: 20px;
  margin-right: 10px;
}

.myButton {
  box-shadow:inset 0px 1px 0px 0px #a4e271;
  background:linear-gradient(to bottom, #89c403 5%, #77a809 100%);
  background-color:#89c403;
  border-radius:6px;
  border:1px solid #74b807;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-size:18px;
  font-weight:bold;
  padding:6px 24px;
  margin: 20px;
  text-decoration:none;
  text-shadow:0px 1px 0px #528009;
}

.myButtonClear {
  background:linear-gradient(to bottom, #fc8d83 5%, #e4685d 100%);
  background-color:#fc8d83;
  border-radius:6px;
  border:1px solid #d83526;
}

.myButton:hover {
  background:linear-gradient(to bottom, #77a809 5%, #89c403 100%);
  background-color:#77a809;
}

.myButtonClear:hover {
  background:linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
  background-color:#e4685d;
}

.myButton:active {
  position:relative;
  top:1px;
}

.Result-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.Result-section div {
  margin: 10px;
}

.cheapest {
  border-radius: 5%;
  border: solid 3px green;
}

.Converted-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Form-messages {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-holder {
  padding-bottom: 150px;
}

.footer {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background-color: #53B157;
  width: 100%;
  overflow: hidden;
  height: 120px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (min-device-width: 275px) and (max-device-width: 812px) { 

  .Intro p {
    width: 90%;
  }

  .Convert-app {
    margin: 2px;
  }

  .Convert-form div {
    margin: 5px;
  }

  .Form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Form-input {
    width: 100px;
  }

  .Result-section {
    display: flex;
    flex-direction: column;
    margin: 5px;
  }

  .Converted-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


}









